import React, {useEffect, useState} from "react";
import {
    Card,
    Button,
    Layout,
    ConfigProvider,
    Input,
    Pagination,
    Cascader,
    List,
    Tag,
    Flex,
    Modal,
    Space,
} from "antd";
import "antd/dist/reset.css";
import "../styles/App.css";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const {Search} = Input;

const options = [
    {
        value: "australia",
        label: "Australia",
        children: [
            {
                value: "nsw",
                label: "New South Wales",
            },
            {
                value: "vic",
                label: "Victoria",
            },
            {
                value: "qld",
                label: "Queensland",
            },
            {
                value: "wa",
                label: "Western Australia",
            },
            {
                value: "sa",
                label: "South Australia",
            },
            {
                value: "tas",
                label: "Tasmania",
            },
            {
                value: "act",
                label: "Australian Capital Territory",
            },
            {
                value: "nt",
                label: "Northern Territory",
            },
        ],
    },
];

const KeywordsTags = [
    "accountant",
    "hr",
    "sales",
    "analyst",
    "marketing",
    "architect",
    "manager",
    "engineer",
    "consultant",
];

const SearchPage = () => {
    const [allJobs, setAllJobs] = useState([]);
    const [searchResults, setSearchResults] = useState([]); // Holds the search results
    const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
    const [pageSize, setPageSize] = useState(5); // The number of results per page
    const [searchTerm, setSearchTerm] = useState(""); // Stores the search term
    const [selectedTag, setSelectedTag] = useState(null); // Stores the selected tag
    const [selectedLocation, setSelectedLocation] = useState(null); // Stores selected location
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalLink, setModalLink] = useState("");
    const {t} = useTranslation();
    // Handles search term input change
    // const handleSearchChange = (e) => {
    //   setSearchTerm(e.target.value); // Updates the search term
    // };
    useEffect(() => {
        // Fetch all jobs data when the component mounts
        const fetchAllJobs = async () => {
            try {
                //"/api/fetch-all-jobs"
                //"http://localhost:3000/fetch-all-jobs"
                const response = await fetch("/api/fetch-all-jobs");
                if (response.ok) {
                    const data = await response.json();
                    setAllJobs(data);
                    // Initialize search results with random 100 jobs
                    setSearchResults(getRandomJobs(data, 100));
                } else {
                    console.error("Failed to fetch job data");
                }
            } catch (error) {
                console.error("Network error:", error);
            }
        };

        fetchAllJobs();
    }, []);

//get random jobs without search
    const getRandomJobs = (jobs, count) => {
        const shuffled = [...jobs].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    // New effect to trigger search when searchTerm, selectedTag, or selectedLocation changes
    useEffect(() => {
        if (searchTerm || selectedTag || (selectedLocation && selectedLocation.length > 0)) {
            handleSearch(searchTerm);
        }
    }, [searchTerm, selectedTag, selectedLocation]);

    // Handles tag selection and deselection, and sets search term to the tag value
    const handleTagClick = (tag) => {
        if (selectedTag === tag) {
            // If the tag is already selected, deselect it and clear the search term
            setSelectedTag(null);
            setSearchTerm("");
        } else {
            // Select the tag and set the search term to the tag's value
            setSelectedTag(tag);
            setSearchTerm(tag);
        }
    };

    // Handles location change in the Cascader
    const handleLocationChange = (value) => {
        setSelectedLocation(value); // Updates the selected location
    };

    // Handles the search action when the "Search" button is clicked
    // const handleSearch = async (value) => {
    //     let finalSearchTerm = value || searchTerm;
    //     if (selectedLocation && selectedLocation.length > 0) {
    //         // Combine the search term and location for the search request
    //         finalSearchTerm = `${value} ${selectedLocation.join(" ")}`;
    //     }
    //
    //     console.log("Search term:", finalSearchTerm);
    //
    //     try {
    //         //"http://localhost:3000/search-jobs"
    //         //"/api/search-jobs"
    //         const response = await fetch("http://localhost:3000/search-jobs", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({searchText: finalSearchTerm}),
    //         });
    //         const data = await response.json();
    //         if (response.ok) {
    //             setSearchResults(data);
    //             setCurrentPage(1);
    //             setSelectedTag(null); // Reset the selected tag after search
    //         } else {
    //             setSearchResults([]);
    //             setCurrentPage(1);
    //             console.error("Search failed:", data);
    //         }
    //     } catch (error) {
    //         setSearchResults([]);
    //         setCurrentPage(1);
    //         console.error("Network error:", error);
    //     }
    // };

    //new search function based on local
    const handleSearch = () => {
        let finalSearchTerm = searchTerm.toLowerCase();
        let locationFilter = selectedLocation ? selectedLocation[selectedLocation.length - 1].toLowerCase() : null;

        const filteredResults = allJobs.filter(job => {
            const matchesSearch = finalSearchTerm === "" ||
                job.job_title.toLowerCase().includes(finalSearchTerm) ||
                job.company_name.toLowerCase().includes(finalSearchTerm);

            const matchesLocation = !locationFilter ||
                job.job_location.toLowerCase().includes(locationFilter);

            return matchesSearch && matchesLocation;
        });

        setSearchResults(filteredResults);
        setCurrentPage(1);
    };

    // Get the results for the current page
    const currentResults = searchResults.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );
    const showModal = (link) => {
        setModalLink(link);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalLink("");
    };
    return (
        <ConfigProvider>
            <Content
                // style={{width: "150%", transform: "translate(-15%, 0)"}}
                style={{width: "150%", maxWidth: "1200px", marginLeft: '-25%'}}
                id="search"
            >
                <h2
                    style={{
                        color: "#35649e",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Tahoma",
                        fontWeight: "600",
                        fontSize: 30,
                    }}
                >
                    {t("searchForJobs")}
                </h2>
                <Card
                    itemID={"search-content"}
                    style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    }}
                    bordered={false}
                >
                    <div
                        style={{
                            padding: "20px",
                        }}
                    >
                        <Space itemID={"search-bar"} direction="vertical" size="middle" style={{width: '100%'}}>
                            <Space.Compact style={{width: '100%', display: 'flex'}}>
                                <Cascader
                                    size="large"
                                    options={options}
                                    placeholder="Location"
                                    onChange={handleLocationChange} // Handle location change
                                    changeOnSelect
                                    defaultValue={["Australia"]}
                                    style={{flex: 0.8, minWidth: '130px'}}
                                />
                                <Search
                                    size="large"
                                    placeholder={t("searchPlaceholder")}
                                    value={searchTerm} // Controlled input by searchTerm
                                    onChange={(e) => setSearchTerm(e.target.value)} // Handle typing in the search box
                                    onSearch={handleSearch}
                                    enterButton
                                    style={{flex: 2, minWidth: '200px'}}
                                />
                                {/*<Button*/}
                                {/*    size="large"*/}
                                {/*    type="primary"*/}
                                {/*    style={{fontWeight: "bold", flex: 1}}*/}
                                {/*    onClick={handleSearch} // Search is now triggered on button click*/}
                                {/*>*/}
                                {/*    {t("search")}*/}
                                {/*</Button>*/}
                            </Space.Compact>
                        </Space>
                    </div>

                    {/* Keyword Quick Search */}
                    <Flex
                        gap={4}
                        wrap
                        align="center"
                        style={{margin: " 0  0 30px 20px"}}
                    >
                        <span style={{fontWeight: "500"}}>{t("popularJobs")} &nbsp;</span>
                        {KeywordsTags.map((tag) => (
                            <Tag.CheckableTag
                                key={tag}
                                checked={selectedTag === tag} // Check if the tag is selected
                                onChange={() => handleTagClick(tag)} // Handle tag selection and deselection
                                style={{cursor: "pointer", fontSize: "100%"}}
                            >
                                {tag}
                            </Tag.CheckableTag>
                        ))}
                    </Flex>

                    <div
                        itemID={"data-box"}
                        style={{
                            margin: "20px",
                            padding: "20px",
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        }}
                    >
                        <List
                            size="large"
                            bordered
                            header={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",

                                        fontWeight: "bold",
                                        padding: "0",
                                    }}
                                >
                                    <div style={{flex: 1, maxWidth: "500px"}}>
                                        {t("position")}
                                    </div>
                                    <div style={{flex: 0, minWidth: "100px"}}>
                                        {t("salary")}
                                    </div>
                                    <div>{t("navi")}</div>
                                </div>
                            }
                            dataSource={currentResults}
                            renderItem={(item) => (
                                <List.Item
                                    style={{
                                        borderBottom: "1px solid #f0f0f0",
                                        borderRadius: "5px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{flex: 1, maxWidth: "500px", marginRight: "5px"}}
                                    >
                                        {/* Title and company with more space */}
                                        <div style={{fontWeight: "bold"}}>
                                            {item.job_title} - {item.company_name}
                                        </div>
                                    </div>
                                    <div style={{flex: 0, minWidth: "100px"}}>
                                        {/* Salary with less space, does not grow */}
                                        {item.salary ? item.salary : "Not provided"}
                                    </div>
                                    {/* <Button type="link" onClick={() => showModal(item.link)}>
                    {t("viewJobs")}
                  </Button> */}
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        {t("viewJobs")}
                                    </a>
                                </List.Item>
                            )}
                        />
                    </div>

                    {/* Pagination Component */}
                    <div style={{justifyContent: "center", display: "flex"}}>
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            style={{marginTop: "20px", textAlign: "center"}}
                            onChange={(page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            }}
                            total={searchResults.length}
                        />
                    </div>
                </Card>
                {/* View Job 弹窗态埋点（防火墙锁死非单独页面访问 目前仅unsw可访问） */}
                {/* <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width="80%"
          style={{ top: 20 }}
        >
          <iframe
            src={modalLink}
            title="Job Details"
            style={{ width: "100%", height: "80vh", border: "none" }}
          ></iframe>
        </Modal> */}
            </Content>
        </ConfigProvider>
    );
};

export default SearchPage;
