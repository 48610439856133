import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  ConfigProvider,
  Button,
  Modal,
} from "antd";
import Icon, { FacebookOutlined, SlackOutlined } from "@ant-design/icons";
import "../styles/App.css";
import { useNavigate } from "react-router-dom";
import CancelPage from "../pages/CancelPage";
import TeamPage from "../pages/TeamPage";
import AboutPage from "../pages/AboutPage";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;

const NotionSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="22px"
    height="22px"
  >
    <path
      fill="currentColor"
      d="M10.849,10.643c1.308,1.063,1.799,0.982,4.256,0.818l23.161-1.391	c0.492,0,0.083-0.49-0.081-0.571l-3.846-2.781c-0.737-0.572-1.719-1.227-3.601-1.064L8.312,7.288	c-0.818,0.081-0.981,0.49-0.655,0.818L10.849,10.643z M12,16.165V40.29c0,1.296,0.649,1.782,2.112,1.702l25.262-1.458	C40.837,40.454,41,39.561,41,38.508V14.545c0-1.051-0.406-1.619-1.3-1.538L13.3,14.545C12.326,14.626,12,15.113,12,16.165L12,16.165	z M37.441,16.724c0.166,0.746,0,1.491-0.747,1.575l-1.242,0.247v18.213c-1.078,0.579-2.072,0.91-2.9,0.91	c-1.326,0-1.659-0.414-2.652-1.655L21.78,23.265V35.6l2.57,0.579c0,0,0,1.49-2.074,1.49L16.561,38c-0.166-0.331,0-1.159,0.579-1.324	l1.492-0.414V19.954l-2.071-0.166c-0.167-0.746,0.247-1.821,1.408-1.905L24.1,17.47l8.451,12.915V18.96l-2.155-0.247	c-0.166-0.912,0.497-1.574,1.325-1.655L37.441,16.724z M6.515,5.102l23.124-1.703c2.84-0.243,3.571-0.08,5.355,1.216l7.382,5.188	C43.594,10.695,44,10.937,44,11.91v28.455c0,1.783-0.649,2.838-2.921,2.999l-26.855,1.622c-1.705,0.081-2.517-0.162-3.409-1.297	l-5.436-7.053C4.405,35.338,4,34.367,4,33.231V7.937C4,6.479,4.649,5.263,6.515,5.102z"
    />
  </svg>
);
const NotionIcon = (props) => <Icon component={NotionSvg} {...props} />;

//点击techlauncher.autojob@gmail.com时自动跳转邮箱发邮件
const AppFooter = ({ setSelectedKey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleEmailClick = () => {
    const email = "techlauncher.autojob@gmail.com";
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };
  //取消订阅弹窗
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const showCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  //团队弹窗
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const showTeamModal = () => setIsTeamModalOpen(true);
  const handleTeamModalClose = () => setIsTeamModalOpen(false);

  //About弹窗
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const showAboutModal = () => {
    setIsAboutModalOpen(true);
  };

  const handleAboutModalClose = () => {
    setIsAboutModalOpen(false);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSelectedKey("2");
    navigate("/");
    setTimeout(() => {
      const element = document.querySelector("#search");
      if (element) {
        const yPosition =
          element.getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          cssVar: true,
          components: {
            Button: {
              paddingInline: "0",
              colorLink: "#d1d9de",
              colorTextBase: "#d1d9de",
              colorPrimary: "#d1d9de",
              colorLinkHover: "#ffffff",
              colorLinkActive: "#ffffff",
            },
          },
        }}
      >
        <Footer
          style={{
            backgroundColor: "rgb(0, 21, 41)",
            padding: "10px 0",
            textAlign: "center",
          }}
        >
          <Row
            justify="center"
            align="top"
            gutter={[16, 16]}
            style={{
              width: "100%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <Col
              xs={24}
              sm={12}
              md={12}
              style={{
                textAlign: "left",
              }}
            >
              <Title
                level={5}
                style={{
                  marginTop: "2%",
                  marginBottom: "1%",
                  color: "#d1d9de",
                }}
              >
                {t("ourWebsite")}
              </Title>

              <Paragraph
                style={{
                  width: "75%",
                  color: "#d1d9de",
                }}
              >
                <a
                  type="link"
                  onClick={showAboutModal}
                  style={{ color: "#d1d9de" }}
                >
                  {t("aboutDescription")}
                </a>
              </Paragraph>
            </Col>

            <Col
              xs={24}
              sm={6}
              md={6}
              style={{
                color: "#d1d9de",
                textAlign: "left",
              }}
            >
              <Title
                level={5}
                style={{
                  marginTop: "4%",
                  marginBottom: "2%",
                  color: "#d1d9de",
                }}
              >
                {t("usefulLinks")}
              </Title>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ paddingTop: "1%" }}>
                  <Button type="link" onClick={showCancelModal}>
                    {t("unsubscribe")}
                  </Button>
                  <li>
                    <Button type="link" onClick={handleSearchClick}>
                      {t("searchLink")}
                    </Button>
                  </li>
                </li>
                <li>
                  <Button type="link" href="/sitemap">
                    {t("siteMap")}
                  </Button>
                </li>
              </ul>
            </Col>
            <Col
              xs={24}
              sm={6}
              md={6}
              id="contact-us"
              style={{
                color: "#d1d9de",
                textAlign: "left",
              }}
            >
              <Title
                level={5}
                style={{
                  marginTop: "4%",
                  marginBottom: "2%",
                  color: "#d1d9de",
                }}
              >
                {t("contactUs")}
              </Title>

              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ paddingTop: "1%" }}>
                  <Button type="link" onClick={showTeamModal}>
                    {t("ourTeam")}
                  </Button>
                </li>
                <li>
                  <Button type="link" onClick={handleEmailClick}>
                    techlauncher.autojob@gmail.com
                  </Button>
                </li>
                <li style={{ marginTop: "2%" }}>
                  <Button
                    type="link"
                    icon={<FacebookOutlined style={{ fontSize: "22px" }} />}
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginRight: "3%",
                    }}
                  ></Button>
                  <Button
                    type="link"
                    icon={<SlackOutlined style={{ fontSize: "22px" }} />}
                    href="https://slack.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginRight: "3%",
                    }}
                  ></Button>
                  <Button
                    type="link"
                    icon={<NotionIcon style={{ colorLinkHover: "#ffffff" }} />}
                    href="https://www.notion.so/AutoJob-Sem2-2024-3a15c2f78dfa424ab554fb2439d3dc13"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></Button>
                </li>
              </ul>
            </Col>
          </Row>
          <Paragraph
            style={{
              paddingTop: "0.5%",
              paddingBottom: "0.5%",
              marginBottom: "0%",
              color: "#d1d9de",
            }}
          >
            {t("copyright")}
          </Paragraph>
        </Footer>
      </ConfigProvider>
      {/* Unsubcribe弹窗 */}
      <Modal
        title={<span style={{ fontWeight: "bold" }}> {t("unsubscribe")}</span>}
        centered
        open={isCancelModalOpen}
        onCancel={handleCancelModalClose}
        footer={null}
        width={800}
      >
        <CancelPage handleCancelModalClose={handleCancelModalClose} />
      </Modal>

      {/* OurTeam弹窗 */}
      <Modal
        centered
        open={isTeamModalOpen}
        onCancel={handleTeamModalClose}
        footer={null}
        width={800}
        className="custom-no-padding-modal"
      >
        <TeamPage handleTeamModalClose={handleTeamModalClose} />
      </Modal>

      {/* About弹窗 */}
      <Modal
        visible={isAboutModalOpen}
        onCancel={handleAboutModalClose}
        centered
        footer={null}
        width={1000}
        className="custom-no-padding-modal"
      >
        <AboutPage />
      </Modal>
    </>
  );
};

export default AppFooter;
