import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigProvider, Form, Input, Button, message, Layout } from "antd";
import "antd/dist/reset.css";
import "../styles/index.css";

const { Header, Content } = Layout;

const CancelPage = ({ handleCancelModalClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isCodeSent, setIsCodeSent] = useState(false);

  const sendVerificationCode = async () => {
    const email = form.getFieldValue("email");
    if (email) {
      try {
        // Call backend API to send verification code
        const response = await fetch("/api/send-verification-code", {
          // const response = await fetch('http://localhost:3000/send-verification-code', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          message.success("Verification code sent to your email!");
          setIsCodeSent(true);
        } else {
          throw new Error("Failed to send verification code.");
        }
      } catch (error) {
        message.error("Error sending verification code");
        console.error("Error sending verification code:", error);
      }
    } else {
      message.error("Please enter your email first!");
    }
  };

  const onFinish = async (values) => {
    const { email, verificationCode } = values;

    try {
      // First, verify the verification code
      const verifyResponse = await fetch("/api/verify-code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, verificationCode }),
      });

      if (verifyResponse.ok) {
        // If the verification code is correct, proceed to submit the form to the unsubscribe endpoint
        const submitResponse = await fetch("/api/unsubscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }), // Assuming only the email is needed to unsubscribe
        });

        if (submitResponse.ok) {
          const result = await submitResponse.json();
          console.log("Unsubscription result:", result);
          message.success("You have been unsubscribed successfully!");
          handleCancelModalClose();
        } else {
          throw new Error("Unsubscription failed.");
        }
      } else {
        // Handle incorrect verification code
        message.error(
          "Verification failed. Please check the code and try again."
        );
      }
    } catch (error) {
      console.error(
        "There was a problem with the verification or unsubscription:",
        error
      );
      message.error("An error occurred during the process.");
    }
  };

  return (
    <ConfigProvider>
      <Content
        style={{
          marginTop: "3rem",
        }}
      >
        <div
          className="site-layout-content"
          style={{ margin: "30px auto", maxWidth: 600 }}
        >
          <Form
            form={form}
            name="user-info"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              label={t("emailLabel")}
              rules={[
                { required: true, message: t("emailInputMessage") },
                {
                  type: "email",
                  message: t("invalidEmailMessage"),
                },
              ]}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input
                addonAfter={
                  <Button
                    type="primary"
                    onClick={sendVerificationCode}
                    className="green-btn"
                  >
                    {t("verifyButton")}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              name="verificationCode"
              label={t("verificationCodeLabel")}
              rules={[
                {
                  required: true,
                  message: t("verificationCodeInputMessage"),
                },
              ]}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input />
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="default"
                style={{ marginRight: "1.5rem" }}
                onClick={handleCancelModalClose}
              >
                {t("cancelButton")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ fontWeight: "bold", marginTop: "20px" }}
              >
                {t("submitButton")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </ConfigProvider>
  );
};

export default CancelPage;
