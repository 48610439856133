import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  message,
  Layout,
  Menu,
  Card,
  Select,
  Typography,
  Slider,
  Result,
  Checkbox,
} from "antd";

import "antd/dist/reset.css";
import "../styles/index.css";
import { SmileOutlined } from "@ant-design/icons";
import { Outlet, Link } from "react-router-dom";
import { Row, Col, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const FormPage = ({ email, handleModalClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [salaryRange, setSalaryRange] = useState([100, 900]);
  const [accepted, setAccepted] = useState(true); // 默认勾选
  const [acceptWarning, setAcceptWarning] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Set the email field with the passed email from state
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);

  const onSliderChange = (value) => {
    setSalaryRange(value);
    form.setFieldsValue({ salaryRange: value });
  };

  const onMinSalaryChange = (value) => {
    setSalaryRange([value, salaryRange[1]]);
    form.setFieldsValue({ salaryRange: [value, salaryRange[1]] });
  };

  const onMaxSalaryChange = (value) => {
    setSalaryRange([salaryRange[0], value]);
    form.setFieldsValue({ salaryRange: [salaryRange[0], value] });
  };

  const sendVerificationCode = async () => {
    const email = form.getFieldValue("email");
    if (email) {
      try {
        // Call backend API to send verification code
        const response = await fetch("/api/send-verification-code", {
          // const response = await fetch('httpss://140.238.204.173:3000/send-verification-code', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          message.success({ content: "Verification code sent to your email!" });
          setIsCodeSent(true);
        } else {
          throw new Error("Failed to send verification code.");
        }
      } catch (error) {
        message.error("Error sending verification code");
        console.error("Error sending verification code:", error);
      }
    } else {
      message.error("Please enter your email first!");
    }
  };

  const handleBackClick = () => {
    setFormSubmitted(false);
  };

  const onFinish = async (values) => {
    const { email, verificationCode, name, keywords } = values;
    const salary_max = salaryRange[1] * 1000;
    const salary_min = salaryRange[0] * 1000;

    try {
      // 先验证验证码
      const verifyResponse = await fetch("/api/verify-code", {
        // const verifyResponse = await fetch('https://140.238.204.173:3000/verify-code', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, verificationCode }),
      });

      if (verifyResponse.ok) {
        // if (true) {
        // 验证码正确，继续提交表单
        const submitResponse = await fetch("/api/submit-form", {
          // const submitResponse = await fetch('httpss://140.238.204.173:3000/submit-form', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            name,
            keywords,
            salary_max,
            salary_min,
          }), // 包括email, name, keywords等其他表单数据
        });

        console.log(submitResponse.body);

        if (submitResponse.ok) {
          // if (true) {
          const result = await submitResponse.json();
          console.log("Submission result:", result);
          setFormSubmitted(true);
          handleModalClose();
        } else {
          throw new Error("Form submission failed.");
        }
      } else {
        // 验证码错误处理
        message.error(
          "Verification failed. Please check the code and try again."
        );
      }
    } catch (error) {
      console.error(
        "There was a problem with the verification or form submission:",
        error
      );
      message.error("An error occurred during the process.");
    }
  };

  if (formSubmitted) {
    return (
      <>
        <Result
          icon={<SmileOutlined />}
          title={t("subcribeSuccess")}
          T
          extra={
            <>
              <Typography style={{ marginTop: "16px", textAlign: "center" }}>
                <Typography.Paragraph>
                  {t("subcribeSuccessMessage")}
                </Typography.Paragraph>
              </Typography>
              <Button type="primary" onClick={handleModalClose()}>
                {t("back")}
              </Button>
            </>
          }
        />
      </>
    );
  }

  return (
    <ConfigProvider>
      <Content
        style={{
          marginTop: "2.5rem",
        }}
      >
        <div
          className="site-layout-content"
          style={{ margin: "15px auto", maxWidth: 600 }}
        >
          <Form
            form={form}
            name="user-info"
            onFinish={onFinish}
            autoComplete="off"
            style={{ padding: 10 }}
          >
            <Form.Item
              name="email"
              label={t("emailLabel")}
              rules={[
                { required: true, message: t("emailInputMessage") },
                { type: "email", message: t("invalidEmailMessage") },
              ]}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input
                addonAfter={
                  <Button
                    type="primary"
                    onClick={sendVerificationCode}
                    className="green-btn"
                  >
                    {t("verifyButton")}
                  </Button>
                }
              />
            </Form.Item>
            <Form.Item
              name="verificationCode"
              label={t("verificationCodeLabel")}
              rules={[
                {
                  required: true,
                  message: t("verificationCodeInputMessage"),
                },
              ]}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label={t("name")}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="keywords" // This should match the server-side code
              label={t("keywords")}
              rules={[
                {
                  required: true,
                  message: t("inputAtLeastOneKeyword"),
                },
              ]}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder={t("keywordsPlaceholder")}
              >
                {/* Dynamically generated options, if necessary */}
              </Select>
            </Form.Item>
            <Form.Item
              name="salaryRange"
              label={t("salaryRange")}
              labelCol={{
                style: { fontWeight: "bold" },
              }}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Slider
                    range
                    marks={{
                      10: "10k",
                      1000: "1000k",
                    }}
                    value={salaryRange}
                    onChange={onSliderChange}
                    min={10}
                    max={1000}
                  />
                </Col>
                <Col span={6}>
                  <InputNumber
                    min={10}
                    max={salaryRange[1]}
                    value={salaryRange[0]}
                    onChange={onMinSalaryChange}
                    formatter={(value) => `${value}k`}
                    parser={(value) => value.replace("k", "")}
                  />
                </Col>
                <Col span={6}>
                  <InputNumber
                    min={salaryRange[0]}
                    max={1000}
                    value={salaryRange[1]}
                    onChange={onMaxSalaryChange}
                    formatter={(value) => `${value}k`}
                    parser={(value) => value.replace("k", "")}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              initialValue={true}
              style={{
                color: acceptWarning ? "#ff4d4f" : "inherit",
                textAlign: "center",
              }}
            >
              <Checkbox
                checked={accepted}
                onChange={(e) => setAccepted(e.target.checked)}
              >
                {t("acceptText")}{" "}
                <a
                  href="/sources/documents/Privacy Policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyPolicy")}
                </a>{" "}
                {t("and")}{" "}
                <a
                  href="/sources/documents/Terms and Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("termsConditions")}
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="default"
                style={{ marginRight: "1.5rem" }}
                onClick={handleModalClose}
              >
                {t("cancelButton")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ fontWeight: "bold" }}
                disabled={!accepted}
              >
                {t("submitButton")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </ConfigProvider>
  );
};

export default FormPage;
