import React, { useState, useEffect } from "react";
import "dayjs/locale/zh-cn";
import { Layout, Menu, Image, ConfigProvider } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import "./styles/App.css";
import "./styles/chatbox.css";
import "react-chat-widget/lib/styles.css";
import { Widget, addResponseMessage } from "react-chat-widget";
import AppFooter from "./components/APPFooter.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;
const Chatbot = () => {
  useEffect(() => {
    addResponseMessage("Welcome to our website!");
  }, []);

  // 处理用户消息
  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // 现在这里只是输出到控制台，可以集成聊天机器人后端
    addResponseMessage(
      "Your message is important to us! We'll be with you shortly."
    );
  };

  return (
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      title="AutoJob"
      subtitle="Q&A"
    />
  );
};

const AppLayout = () => {
  const [visible, setVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const onCreate = (values, isLogin) => {
    console.log("Received values of form: ", values);
    console.log("Form type: ", isLogin ? "Login" : "Register");
    setVisible(false);
    // 在这里添加登录或注册逻辑
  };

  const scrollToAnchor = (anchorId, offset = 0) => {
    navigate("/");
    setTimeout(() => {
      const element = document.querySelector(anchorId);
      if (element) {
        const yPosition =
          element.getBoundingClientRect().top + window.pageYOffset + offset;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        token: {
          borderRadius: 5,
          colorPrimary: "#35649e",
          colorInfo: "#35649e",
          colorPrimaryTextHover: "#54a7ec",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div style={{ flex: "1" }}>
          <Layout style={{ padding: "0" }}>
            <Header
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "fixed",
                zIndex: 1000,
                width: "100%",
              }}
            >
              <div
                style={{
                  marginLeft: "0",
                  marginRight: "3%",
                  fontWeight: "600",
                  fontSize: "150%",
                  letterSpacing: "0.05em",
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                <a href="/" style={{ color: "white" }}>
                  {t("AutoJob")}
                </a>
              </div>
              <Menu
                theme="dark"
                mode="horizontal"
                selectedKeys={[selectedKey]}
                onClick={(e) => setSelectedKey(e.key)}
                style={{
                  flex: 1,
                }}
              >
                <Menu.Item key="1" icon={<AppstoreOutlined />}>
                  <a
                    href="#home-page"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToAnchor("#home-page", -100);
                      setSelectedKey("1");
                    }}
                  >
                    {t("home")}
                  </a>
                </Menu.Item>
                <Menu.Item key="2" icon={<SearchOutlined />}>
                  <a
                    href="#search"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToAnchor("#search", -200);
                      setSelectedKey("2");
                    }}
                  >
                    {t("search")}
                  </a>
                </Menu.Item>

                <Menu.Item key="3" icon={<MailOutlined />}>
                  <a
                    href="#subscribe"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToAnchor("#subscribe", -180);
                      setSelectedKey("3");
                    }}
                  >
                    {t("subscribe")}
                  </a>
                </Menu.Item>
                <Menu.SubMenu
                  key="SubMenu"
                  icon={<GlobalOutlined />}
                  title={t("language")}
                >
                  <Menu.Item key="en" onClick={() => changeLanguage("en")}>
                    {"English"}
                  </Menu.Item>
                  <Menu.Item key="zh" onClick={() => changeLanguage("zh")}>
                    {"中文"}
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
              <div style={{ marginLeft: "auto", zIndex: 2000 }}>
                <Image
                  src="/sources/logo.png"
                  alt="logo"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "40px",
                    borderRadius: "8px",
                    border: "3px solid black",
                  }}
                />
              </div>
            </Header>
            <Outlet context={{ setSelectedKey, scrollToAnchor }} />
          </Layout>
        </div>
        {/* <Chatbot />在实现前暂时隐藏 */}
        <AppFooter setSelectedKey={setSelectedKey} />
      </div>
    </ConfigProvider>
  );
};

export default AppLayout;
