import React from "react";
import { Typography, Layout, ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "../styles/App.css";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { Content } = Layout;

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <ConfigProvider>
      <Content>
        <div style={{ display: "flex", alignItems: "left" }}>
          <img
            src={require("../sources/modalImg-1.png")}
            alt="Team"
            style={{ maxWidth: "30%" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: "1.35rem",
                fontWeight: "600",
                marginTop: "2rem",
                textAlign: "left",
                paddingLeft: "2rem",
              }}
            >
              {t("aboutAutoJob")}
            </h1>
            <div style={{ padding: "0.6rem 2rem 2rem 2rem" }}>
              <Paragraph style={{ fontSize: "1rem" }}>
                {t("aboutDescriptionPart1")}
              </Paragraph>
              <Paragraph style={{ fontSize: "1rem" }}>
                {t("aboutDescriptionPart2")}
              </Paragraph>
              <Paragraph style={{ fontSize: "1rem" }}>
                {t("aboutDescriptionPart3")}
              </Paragraph>
            </div>
          </div>
        </div>
      </Content>
    </ConfigProvider>
  );
};

export default AboutPage;
