// HomePage.js
import React, { useState } from "react";
import { Button, Typography, Row, Col, Modal } from "antd";
import "antd/dist/reset.css";
import "../styles/App.css";
import AboutPage from "../pages/AboutPage";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Title, Paragraph } = Typography;

const HomePage = () => {
  const { setSelectedKey } = useOutletContext();
  const { t } = useTranslation();
  const [isAboutModalOpen, setIsAboutModalOpen] = React.useState(false);
  const showAboutModal = () => {
    setIsAboutModalOpen(true);
  };

  const handleAboutModalClose = () => {
    setIsAboutModalOpen(false);
  };
  return (
    <Row
      id="home-page"
      style={{
        backgroundColor: "#35649e",
        height: "95vh",
        paddingBottom: "0",
      }}
    >
      <Col
        itemID={"home-background-image"}
        span={12}
        style={{
          textAlign: "center",
          height: "100%",
        }}
      >
        <img
          src={require("../sources/HomeImg.png")}
          alt="Illustration"
          className="fade-in delay-1"
          style={{
            position: "absolute",
            left: "0",
            width: "150%",
            Top: "0",
            zIndex: "0",
          }}
        />
      </Col>
      <Col
        span={12}
        style={{
          textAlign: "left",
          padding: "5% 5% 5% 0%",
          margin: "2% auto 3% auto",
          position: "relative",
        }}
      >
        <div
          itemID={"home-content"}
          className="fade-in-down delay-2"
          style={{
            backgroundColor: "rgba(88, 139, 175, 0.75)",
            padding: "5%",
            marginTop: "10%",
            width: "95%",
          }}
        >
          <Title
            itemID={"home-t1-title"}
            level={1}
            className="fade-in-down delay-3"
            style={{
              fontSize: "350%",
              fontWeight: "600",
              paddingBottom: "2%",
              color: "white",
              zIndex: "10",
            }}
          >
            {t("homeTitleLine1")}
            <br />
            {t("homeTitleLine2")}
          </Title>
          <Paragraph
            itemID={"home-welcome-sentence"}
            className="fade-in-down delay-4"
            style={{
              fontSize: "180%",
              width: "85%",
              paddingBottom: "3%",
              color: "white",
            }}
          >
            {t("homeSubtitleLine1")} <br /> {t("homeSubtitleLine2")}
          </Paragraph>
          <div
            itemID={"two-button"}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <Button
              itemID={"discover-button"}
              className="fade-in-up delay-5"
              type="primary"
              size="large"
              href="#search"
              style={{
                fontSize: "120%",
                fontWeight: "500",
                lineHeight: "140%",
              }}
              onClick={(e) => {
                e.preventDefault();
                const yPosition =
                  document.querySelector("#search").getBoundingClientRect()
                    .top +
                  window.pageYOffset -
                  150;
                window.scrollTo({
                  top: yPosition,
                  behavior: "smooth",
                });
                setSelectedKey("2");
              }}
            >
              {t("discoverJobs")}
            </Button>
            <Button
              itemID={"about-button"}
              className="fade-in-up delay-6"
              type="default"
              size="large"
              style={{
                fontSize: "120%",
                fontWeight: "600",
                lineHeight: "140%",
              }}
              onClick={showAboutModal}
            >
              {t("learnAboutUs")}
            </Button>
          </div>
        </div>
      </Col>
      <Modal
        visible={isAboutModalOpen}
        onCancel={handleAboutModalClose}
        centered
        footer={null}
        width={1000}
        className="custom-no-padding-modal"
      >
        <AboutPage />
      </Modal>
    </Row>
  );
};

export default HomePage;
