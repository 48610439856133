import React from "react";
import HomePage from "./HomePage";
import SearchPage from "./SearchPage";
import { Layout } from "antd";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";
import SubscribeEntry from "../components/SubscribeEntry";
import LogoWall from "../components/LogoWall";
const { Content } = Layout;
const MainPage = () => {
  const handleIntersection = (element) => {
    element.classList.add("fade-in-down", "delay-1");
  };
  const searchRef = useIntersectionObserver(handleIntersection, {
    threshold: 0.1,
  });

  const formRef = useIntersectionObserver(handleIntersection, {
    threshold: 0.1,
  });
  const logoWallRef = useIntersectionObserver(handleIntersection, {
    threshold: 0.1,
  });

  return (
    <div style={{ backgroundColor: "rgb(245, 245, 245)" }}>
      <div style={{ backgroundColor: "#35649e", paddingTop: "3.5%" }}>
        <HomePage />
      </div>
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <div
          ref={searchRef}
          style={{
            padding: "10% 15% 7% 15%",
            maxWidth: "70%",
            margin: "auto",
            opacity: 0,
          }}
        >
          <SearchPage />
        </div>
        <div
          ref={logoWallRef}
          style={{
            opacity: 0,
            transition: "opacity 1s ease-out",
          }}
        >
          <LogoWall />
        </div>
      </div>

      <div
        ref={formRef}
        style={{
          padding: "0 15%",
          maxWidth: "70%",
          margin: "12% auto",
          opacity: 0,
          backgroundColor: "rgb(245, 245, 245)",
        }}
      >
        <SubscribeEntry />
      </div>
    </div>
  );
};

export default MainPage;
