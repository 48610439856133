import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
// import { Typography,  } from "antd";
// const { Title } = Typography;

// Logo icon import from https://img.logo.dev/
const logoData = [
  {
    name: "ACT Government",
    imgSrc:
      "https://img.logo.dev/www.act.gov.au?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "Australian National University",
    imgSrc: "https://img.logo.dev/anu.edu.au?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "Amazon",
    imgSrc: "https://img.logo.dev/amazon.com?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "UNSW",
    imgSrc: "https://img.logo.dev/unsw.edu.au?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "University of Canberra",
    imgSrc:
      "https://img.logo.dev/canberra.edu.au?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "Seeing Machines",
    imgSrc:
      "https://img.logo.dev/seeingmachines.com?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "PwC",
    imgSrc: "https://img.logo.dev/pwc.com?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
  {
    name: "EY",
    imgSrc: "https://img.logo.dev/ey.com?token=pk_Yt4F56p-TlK56VBoHVFgoA",
  },
];

const LogoWall = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    backgroundColor: "transparent",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const logoWallStyle = {
    textAlign: "center",
    padding: "0 0 4% 0",
    backgroundColor: "rgb(255,255,255)",
    width: "100%",
  };

  const logoItemStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "800%",
  };

  const slickSlideImgStyle = {
    margin: "auto",
    maxHeight: "100px",
    height: "100%",
  };

  const titleWithLineStyle = {
    color: "rgb(130, 130, 130)",
    fontSize: "1.8rem",
    fontWeight: "650",
    marginBottom: "4%",
    justifyContent: "flex-start",
    display: "flex",
    paddingLeft: "5%",
    position: "relative",
  };

  return (
    <div style={logoWallStyle}>
      <div style={titleWithLineStyle}>
        <span
          style={{
            borderBottom: "1.5px solid rgb(245,245,245)",
            paddingBottom: "1rem",
          }}
        >
          {t("ourClients")}
        </span>
      </div>

      <Slider {...settings}>
        {logoData.map((company, index) => (
          <div style={logoItemStyle} key={index}>
            <img
              src={company.imgSrc}
              alt={company.name}
              title={company.name}
              style={slickSlideImgStyle}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoWall;
