// 404NotFound界面
import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
const AppLayout = () => {
  const { t } = useTranslation();
  const handleEmailClick = () => {
    const email = "techlauncher.autojob@gmail.com";

    const mailtoLink = `mailto:${email}`;

    window.location.href = mailtoLink;
  };

  return (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <h1
        style={{
          fontWeight: "750",
          fontSize: "800%",
          letterSpacing: "2%",
          marginBottom: "2%",
        }}
      >
        4<span style={{ color: "#35649e" }}>0</span>4
      </h1>
      <h1 style={{ marginBottom: "4.5%", fontWeight: "650" }}>
        Page not found
      </h1>
      <Button
        type="primary"
        size="large"
        href="/"
        style={{ marginRight: "1.5rem", fontWeight: "bold" }}
      >
        {t("takeMeHome")}
      </Button>
      <Button
        type="default"
        size="large"
        style={{ fontWeight: "bold" }}
        onClick={handleEmailClick}
      >
        {t("help")}
      </Button>
    </div>
  );
};

export default AppLayout;
