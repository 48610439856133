import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input, Button, Form, Typography, Space, Checkbox, Modal } from "antd";
import FormPage from "../pages/FormPage";
import CancelPage from "../pages/CancelPage";
import "../styles/SubscribeEntryCss.css";
const { Title, Paragraph } = Typography;

const SubscribeEntry = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [acceptWarning, setAcceptWarning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); //订阅表单弹窗
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false); //取消订阅弹窗
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const showCancelModal = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };
  const handleSubscribe = () => {
    let valid = true;

    if (!email) {
      setEmailWarning(true);
      valid = false;
    } else {
      setEmailWarning(false);
    }

    if (!accepted) {
      setAcceptWarning(true);
      valid = false;
    } else {
      setAcceptWarning(false);
    }

    if (valid) {
      showModal(); // 显示弹窗
    }
  };

  const handleCheckboxChange = (e) => {
    setAccepted(e.target.checked);
  };

  return (
    <div
      id="subscribe"
      style={{ maxWidth: "500px", margin: "0 auto", textAlign: "center" }}
    >
      <Title
        itemID={"Subscribe-Title"}
        style={{
          color: "rgb(0,21,41)",
          fontSize: "1.8rem",
          fontWeight: "650",
          marginBottom: "4%",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        {t("subscribeTitle")}
      </Title>
      <Paragraph
        style={{
          marginBottom: "8%",
          fontSize: "110%",
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        {t("subscribeParagraph")}
      </Paragraph>
      <Form
        layout="inline"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
        onFinish={handleSubscribe}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="email"
          validateStatus={emailWarning ? "error" : ""}
          // help={emailWarning ? "Please enter your email!" : ""}
          rules={[{ required: true, message: " " }]}
        >
          <Space.Compact>
            <Input
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("enterEmail")}
              style={{ width: "25rem" }}
            />
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              onClick={handleSubscribe}
            >
              {t("subscribe")}
            </Button>
          </Space.Compact>
        </Form.Item>
        {/* Legal Documents generated by https://www.lawdepot.com/ on August 28, 2024  */}
        <Form.Item
          style={{
            marginTop: "5%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Checkbox
            autoFocus={false}
            checked={accepted}
            defaultChecked={false}
            disabled={false}
            indeterminate={false}
            onChange={handleCheckboxChange}
            style={{
              color: acceptWarning ? "#ff4d4f" : "inherit",
            }}
          >
            {t("acceptText")}&nbsp;
            <a
              href="/sources/documents/Privacy Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("privacyPolicy")} &nbsp;
            </a>
            {t("and")}&nbsp;
            <a
              href="/sources/documents/Terms and Conditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("termsConditions")}
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item
          style={{
            marginTop: "3%",
            marginRight: "1.5rem",
          }}
        >
          <Link
            style={{
              color: "#80817e",
              textDecoration: "underline",
            }}
            onClick={showCancelModal}
          >
            {t("cancelSubscription")}
          </Link>
        </Form.Item>
      </Form>
      {/* 弹窗部分 */}
      {/* 订阅表单弹窗 */}
      <Modal
        title={
          <span style={{ fontWeight: "bold" }}>
            {t("subscriptionFormTitle")}
          </span>
        }
        centered
        open={isModalOpen}
        onCancel={handleModalClose}
        onOk={handleModalClose}
        footer={null}
        width={800}
      >
        <FormPage email={email} handleModalClose={handleModalClose} />
        {/*  email 继承到 FormPage  */}
      </Modal>
      {/* 取消订阅弹窗 */}
      <Modal
        title={<span style={{ fontWeight: "bold" }}>{t("unsubscribe")}</span>}
        centered
        open={isCancelModalOpen}
        onCancel={handleCancelModalClose}
        footer={null}
        width={800}
      >
        <CancelPage handleCancelModalClose={handleCancelModalClose} />
      </Modal>
    </div>
  );
};

export default SubscribeEntry;
