import React, { useState } from "react";
import { Row, Col, Typography, Card, Modal, ConfigProvider } from "antd";
import { useOutletContext } from "react-router-dom";
import "../styles/App.css";
import AboutPage from "../pages/AboutPage";
import TeamPage from "../pages/TeamPage";
import CancelPage from "../pages/CancelPage";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const SiteMap = () => {
  const { t } = useTranslation();
  const { setSelectedKey, scrollToAnchor } = useOutletContext();
  const handleEmailClick = () => {
    const email = "techlauncher.autojob@gmail.com";
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };
  const handleSearchClick = (e) => {
    e.preventDefault();
    setSelectedKey("2");
    scrollToAnchor("#search", -200);
  };

  const handleSubscribeClick = (e) => {
    e.preventDefault();
    setSelectedKey("3");
    scrollToAnchor("#subscribe", -180);
  };

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);

  const showAboutModal = () => setIsAboutModalOpen(true);
  const handleAboutModalClose = () => setIsAboutModalOpen(false);

  const showTeamModal = () => setIsTeamModalOpen(true);
  const handleTeamModalClose = () => setIsTeamModalOpen(false);

  const showUnsubscribeModal = () => setIsUnsubscribeModalOpen(true);
  const handleUnsubscribeModalClose = () => setIsUnsubscribeModalOpen(false);

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
      }}
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: "transparent",
          padding: "2%",
          margin: "6rem auto 0 5rem ",
          boxShadow: "none",
          width: "65%",
        }}
        title={
          <Title
            level={2}
            style={{
              marginBottom: "0",
              textAlign: "Left",
              // borderBottom: "1px solid #8d8d8d",
            }}
          >
            {t("siteMap")}
          </Title>
        }
      >
        <Row gutter={[24, 24]} justify="center">
          <Col xs={24} sm={12} md={12}>
            <Card
              bordered={false}
              style={{
                borderRadius: "1rem",
                boxShadow: "none",
                textAlign: "left",
                backgroundColor: "transparent",
              }}
            >
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                <li
                  style={{
                    marginBottom: "2rem",
                    fontWeight: "600",
                    fontSize: "150%",
                  }}
                >
                  {t("aboutAutoJob")}
                </li>
                <li style={{ marginBottom: "1rem", fontSize: "110%" }}>
                  <a href="/">{t("home")}</a>
                </li>
                <li
                  style={{ marginBottom: "1rem", fontSize: "110%" }}
                  onClick={showAboutModal}
                >
                  <a>{t("about")}</a>
                </li>
                <li
                  style={{ marginBottom: "1rem", fontSize: "110%" }}
                  onClick={showTeamModal}
                >
                  <a>{t("ourTeam")}</a>
                </li>
                <li style={{ marginBottom: "1rem", fontSize: "110%" }}>
                  <a href="#contact-us">{t("contactUs")}</a>
                </li>
              </ul>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Card
              bordered={false}
              style={{
                borderRadius: "1rem",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                <li
                  style={{
                    marginBottom: "2rem",
                    fontWeight: "600",
                    fontSize: "150%",
                  }}
                >
                  {t("services")}
                </li>
                <li style={{ marginBottom: "1rem", fontSize: "110%" }}>
                  <a onClick={handleSearchClick}> {t("search")}</a>
                </li>
                <li style={{ marginBottom: "1rem", fontSize: "110%" }}>
                  <a onClick={handleSubscribeClick}> {t("subscribe")}</a>
                </li>
                <li
                  style={{ marginBottom: "1rem", fontSize: "110%" }}
                  onClick={showUnsubscribeModal}
                >
                  <a> {t("unsubscribe")}</a>
                </li>
                <li style={{ marginBottom: "1rem", fontSize: "110%" }}>
                  <a onClick={handleEmailClick}> {t("feedback")}</a>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </Card>
      {/* About Modal */}
      <Modal
        title={t("aboutAutoJob")}
        centered
        open={isAboutModalOpen}
        onCancel={handleAboutModalClose}
        footer={null}
        width="50rem"
      >
        <AboutPage />
      </Modal>
      {/* Our Team Modal */}
      <Modal
        title={t("ourTeam")}
        centered
        open={isTeamModalOpen}
        onCancel={handleTeamModalClose}
        footer={null}
        width="50rem"
      >
        <TeamPage />
      </Modal>
      {/* Unsubscribe Modal */}
      <Modal
        title={t("unsubscribe")}
        centered
        open={isUnsubscribeModalOpen}
        onCancel={handleUnsubscribeModalClose}
        footer={null}
        width="50rem"
      >
        <CancelPage />
      </Modal>
    </ConfigProvider>
  );
};

export default SiteMap;
