import React from "react";
import { Typography, Layout, ConfigProvider } from "antd";
import "antd/dist/reset.css";
import "../styles/App.css";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { Content } = Layout;

const TeamPage = () => {
  const { t } = useTranslation();
  return (
    <ConfigProvider>
      <Content>
        <div
          style={{
            display: "flex",
            alignItems: "left",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../sources/modalImg-1.png")}
            alt="Team"
            style={{ maxWidth: "30%" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontSize: "1.35rem",
                fontWeight: "600",
                marginTop: "2rem",
                paddingLeft: "2rem",
              }}
            >
              {t("ourTeam")}
            </h1>
            <Paragraph
              style={{ fontSize: "1rem", padding: "1rem 2rem 2rem 2rem" }}
            >
              {t("ourTeamDescription")}
            </Paragraph>
          </div>
        </div>
      </Content>
    </ConfigProvider>
  );
};

export default TeamPage;
